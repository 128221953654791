import jobad from './jobad';
import summarize from './summarize';
import codeInterpret from './interpret';
import intro from './intro';
import issuedesc from './issuedesc';
import architecture from './architecture';
import resignation from './resignation';
import appcreator from './appcreator';

const TOOLS = [
	codeInterpret,
	intro,
	jobad,
	summarize,
	issuedesc,
	architecture,
	resignation,
	appcreator
]

export default TOOLS
