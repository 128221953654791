import React, { Component } from 'react';
import { PencilIcon } from '@heroicons/react/outline';

import { Col, Grid } from '../Components/Body';
import Output from '../Components/Output';
import Logo from '../Logo';

import { makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';


@inject('store')
@observer
class History extends Component {

  countdown;
  @observable output = '';
  @observable outputs = [];
  @observable outputError = '';

  @observable option = 'Start Using';
  @observable options = [];
  @observable currentOption = '';

  @observable loading = false;
  @observable date = Date.now() + 1000;

  // Basic Input
  @observable prompt = '';
  @observable promptError = '';
  @observable promptNumber = 0;

  // Options of Inputs
  @observable promptOptions = [];

  // Currently Selected Input Option
  @observable currentPromptOption = '';

  // Multiple Input Option
  @observable prompts = [];

  @observable history = [];

  @observable tool = {};

  constructor(props) {
    super(props);
    makeObservable(this);
    this.loading = true
    this.init().then(_ => {
      this.loading = false
    });
  }

  init = async () => {
    await this.refreshHistory();
  };

  refreshHistory = async () => {
    let response = await this.props.store.api.post('/user/history/view');
    this.history = [...response.data];
  };

  // handleFeedback = async () => {
  //   try {
  //     await this.props.store.api.post('/user/feedback', {feedback: this.props.store.feedback});
  //     this.refreshHistory();
  //     this.output = 'Thank you for your feedback!';
  //     this.props.store.feedback = ``;
  //   } catch (err) {
  //     console.log(err);
  //     this.output = 'There seems to have been an error, please try submitting again';
  //   }
  // };

  onClick = async () => {
    this.loading = true;
    // await this.handleFeedback();
    this.date = Date.now() + 10000;
    // this.props.store.feedback = '';
    this.countdown.start();
    this.loading = false;
  };
  onChange = e => {
    this.props.store.feedback = e.target.value;
  };

  render() {
    return (
        <>
          <Grid>
            {/*<Col span="6">*/}
            {/*  <EntryText*/}
            {/*      title="Feedback"*/}
            {/*      desc="Provide some feedback about your experience"*/}
            {/*      prompt={this.props.store.feedback}*/}
            {/*      onChange={this.onChange}*/}
            {/*  />*/}
            {/*  <Countdown*/}
            {/*      ref={countdown => this.countdown = countdown}*/}
            {/*      date={this.date}*/}
            {/*      renderer={props =>*/}
            {/*          <Button*/}
            {/*              title={props.total ? `Timeout ${props.total / 1000} secs` : 'Submit Feedback'}*/}
            {/*              disabled={props.total}*/}
            {/*              Icon={props.total ? ClockIcon : PencilIcon}*/}
            {/*              onClick={this.onClick}*/}
            {/*          />}*/}
            {/*  />*/}
            {/*</Col>*/}
            <Col span="12">
              <Output
                  title={`Global History`}
                  desc={`All Requests with their Responses`}

                  Icon={Logo}
                  fromColor={`yellow-300`}
                  toColor={`yellow-400`}

                  loading={this.loading}
                  output={this.output}

              />
              {this.history && this.history.map((history, index) => <Output
                  key={history._id}
                  title={`${history.api}`}
                  desc={`${history.created} | Credits: ${history.credits} | Input: ${history.input}`}

                  Icon={PencilIcon}
                  fromColor={history.response ? `green-400` : `gray-300`}
                  toColor={history.response ? `green-600` : `gray-400`}


                  output={history.output}
                  outputs={history.outputs ? [history.outputs] : null}

              />)}
            </Col>
          </Grid>
        </>
    );
  }
}


export default History;
