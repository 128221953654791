import {
	ClipboardListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Ticket Description",
	desc: "Quickly create an Issue Description for Jira or YouTrack issues",
	category: "Business",
	Icon: ClipboardListIcon,
	// tags: [],
	permissions: ['user'],

	to: "/ai/business/issuedesc",
	api: "/ai/business/issuedesc",

	fromColor: "blue-600",
	toColor: "yellow-500",

	output: {
		title: "Jira Ticket Preview",
		desc: "Example of a possible jira ticket",
		// Icon: RefreshIcon,
		// color: "",
	},

	prompts: [{
		title:"Basic Ticket",
		desc: "Short but most detailed understandable Ticket Title possible.",
		// n: 1,
		prompts: [
			{
				title: "Ticket Title",
				attr: "content",
				value: "",
				placeholder: "Frontend Project in react with SASS & Routing",
				label: "",
				type: "textarea",
				maxLength: 400,
				// max: 100,
				// min: 1,
				required: true,
				error: "",
				example: "Setup standard react frontend Project with sass & routing",
			},
		],
		example: {
output: `
Story:
As a frontend developer, I need to setup a standard react project that uses SASS for styling and routing to navigate between pages.

Acceptance Criteria:

The project should be created using the latest version of React.
SASS should be properly integrated and configured for use in the project.
Routing should be implemented using a popular library such as React Router.
The project should be properly structured and organized for easy maintenance.
The project should include a basic example of using SASS for styling and routing for navigation between pages.

Documentation Links:

- React: https://reactjs.org/docs/getting-started.html
- SASS: https://sass-lang.com/guide
- React Router: https://reactrouter.com/web/guides/quick-start`,
			// outputs: [],
			// Icon: RefreshIcon,
			// color: "",
		}
	},
		{
			title:"Ticket Style Analysis",
			desc: "Provide an example of your desired ticket style. An Example ticket is perfect.",
			// n: 1,
			prompts: [
				{
					title: "Example Ticket",
					attr: "content",
					value: "",
					placeholder: "Detailed Ticket example with recognizable style",
					label: "",
					type: "textarea",
					maxLength: 800,
					// max: 100,
					// min: 1,
					required: true,
					error: "",
					example: "Story:\n" +
							"As a frontend developer, I need to setup a standard react project that uses SASS for styling and routing to navigate between pages.\n" +
							"\n" +
							"Acceptance Criteria:\n" +
							"\n" +
							"- The project should be created using the latest version of React.\n" +
							"- SASS should be properly integrated and configured for use in the project.\n" +
							"- Routing should be implemented using a popular library such as React Router.\n" +
							"- The project should be properly structured and organized for easy maintenance.\n" +
							"- The project should include a basic example of using SASS for styling and routing for navigation between pages.\n" +
							"\n" +
							"Documentation Links:\n" +
							"\n" +
							"- React: https://reactjs.org/docs/getting-started.html\n" +
							"- SASS: https://sass-lang.com/guide\n" +
							"- React Router: https://reactrouter.com/web/guides/quick-start",
				},
			],
			example: {
				output: `Layout:

The ticket description consists of a title ("Story") and a bullet list of acceptance criteria and documentation links.
The acceptance criteria are numbered and listed as bullet points.
The documentation links are listed as bullet points.
Written style:

The ticket description is written in a concise and clear style.
The language used is technical and specific to the domain of frontend development.
The acceptance criteria are written in a user story format ("As a frontend developer, I need to...").
Paragraphs:

There are no paragraphs in the ticket description. Instead, the content is organized using bullet points.
Other characteristics:

The acceptance criteria are clearly defined and specific.
The documentation links provide additional resources for the developer to refer to.
The ticket description is focused on the technical requirements of the project and does not include any non-technical details or requirements.`,
				// outputs: [],
				// Icon: RefreshIcon,
				// color: "",
			}
		}]

}

export default obj

