import {
	ClipboardListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Letter of Resignation",
	desc: "Quickly create a Letter of Resignation for any use case",
	category: "Content",
	Icon: ClipboardListIcon,
	// tags: [],
	permissions: ['user'],

	to: "/ai/writing/resignation",
	api: "/ai/writing/resignation",

	fromColor: "blue-600",
	toColor: "yellow-500",

	output: {
		title: "Project Spec Preview",
		desc: "Example of a possible project specification",
		// Icon: RefreshIcon,
		// color: "",
	},

	prompts: [{
		title:"Use Case",
		desc: "Short description of the use case",
		// n: 1,
		prompts: [
			{
				title: "Letter of Resignation use case",
				attr: "content",
				value: "",
				placeholder: "for an employee leaving a job on good terms.",
				label: "",
				type: "textarea",
				maxLength: 200,
				// max: 100,
				required: true,
				error: "",
				example: "for an employee leaving a job on good terms.",
			},
		],
		example: {
output: `
[Your Name]
[Your Address]
[City, State ZIP Code]
[Email Address]
[Today's Date]

[Employer's Name]
[Company Name]
[Address]
[City, State ZIP Code]

Dear [Employer's Name],

I am writing to inform you that I have decided to resign from my position as [Job Title] at [Company Name]. My last day of work will be [Date], providing two weeks' notice in accordance with my employment contract.

I would like to thank you and the entire team at [Company Name] for the opportunity to work here over the past [Length of Employment]. I have learned a great deal from my time here and am grateful for the experience.

I would be happy to assist in any way possible to ensure a smooth transition during my remaining time at the company. Please let me know how I can be of assistance during this period.

I look forward to hearing from you and wish [Company Name] and the team continued success in the future.

Sincerely,

[Your Name]

[Your Signature]`,
			// outputs: [],
			// Icon: RefreshIcon,
			// color: "",
		}
	}]

}

export default obj

