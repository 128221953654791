const dev = {
	baseURL: "http://localhost:3080/api/",
	landingPageUrl: "http://localhost:3080",
	stripe: {
		free: "price_1MdbSuFMcqDbJ023W9J59Z26",
		entry: "price_1MdbOoFMcqDbJ023kPguWKQI",
		pro: "price_1MdbNoFMcqDbJ023s1dxKWDC"
	},
};

const prod = {
	baseURL: 'https://api.fallerjansen.de/api/',
	landingPageUrl: "https://ai.fallerjansen.de",
	stripe: {
		free: "price_1MdcfAFMcqDbJ023eHqcIO18",
		entry: "price_1MdcezFMcqDbJ0239FG584P0",
		pro: "price_1MdcedFMcqDbJ023bbhciKxh"
	},
};

const config = process.env.NODE_ENV === 'development'
	? dev
	: prod;

export default config;
