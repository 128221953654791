import {
	ClipboardListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "App Creator",
	desc: "Desing a Prompt and Form based on a Result",
	category: "Beta",
	Icon: ClipboardListIcon,
	// tags: [],
	permissions: ['user'],

	to: "/ai/creator/promptdesign",
	api: "/ai/business/architecture",

	fromColor: "blue-600",
	toColor: "yellow-500",

	output: {
		title: "Prompt Suggestion",
		desc: "Example of a possible prompt which generates the provided Result",
		// Icon: RefreshIcon,
		// color: "",
	},

	prompts: [{
		title: "Target Content",
		desc: "Provide the content which should be reverse engineered",
		// n: 1,
		prompts: [
			{
				title: "Document Type",
				attr: "try to provide a description of the document type",
				value: "",
				placeholder: "poem by Goethe",
				label: "letter of resignation / poem by Goethe..",
				// type: "textarea",
				maxLength: 80,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "poem by Goethe",
			},
			{
				title: "Example Content",
				attr: "content",
				value: "",
				placeholder: "Provide the content which should be reverse engineered into a prompt",
				label: "",
				type: "textarea",
				maxLength: 400,
				// max: 100,
				min: 60,
				required: true,
				error: "",
				example: "A e-commerce with a database which should allow users to browse and purchase products, view their order history, and manage their account information. The database should be designed to store product information, customer information, and order information.",
			}
		],
		example: {
output: `
User Registration and Login: 
- The e-commerce store will have a registration system where users can create a new account and log in using their email and password.
Product Management: 
- The store will have a system for adding, editing, and removing products. Each product will have a name, description, price, and image.
Shopping Cart: 
 - The store will have a shopping cart system where users can add products to their cart and check out when they are ready to purchase.
Order Management: 
 - The store will have a system for managing orders, including viewing order history, tracking order status, and managing returns and refunds.
Customer Management: 
 - The store will have a system for managing customer information, including storing customer details and allowing customers to manage their own account information.

Software Architecture Design:
The e-commerce store will be built using a three-tier architecture, with a presentation layer, application layer, and data layer.

Presentation Layer: 
- The presentation layer will consist of a web-based user interface that allows users to interact with the store. The user interface will be built using React, HTML, and CSS.
Application Layer: 
- The application layer will be responsible for implementing business logic and handling user input. It will be built using Node.js and will communicate with the data layer using RESTful APIs.
Data Layer: 
- The data layer will consist of a database that stores all the data needed by the store. The database will be built using MySQL and will be hosted on a cloud-based server.

The data layer will be designed to store product information, customer information, and order information. The product table will have fields for the product name, description, price, and image. The customer table will have fields for the customer name, email, password, and other details. The order table will have fields for the order ID, customer ID, product ID, quantity, and order status.

Overall, this software architecture design provides a scalable and maintainable solution for building an e-commerce store with a database.`,
			// outputs: [],
			// Icon: RefreshIcon,
			// color: "",
		}
	}]
}

export default obj
